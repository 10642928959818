* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "IBM Plex Sans", sans-serif;
  /* font-smoothing: antialiased; */
}

.cursor {
  cursor: pointer;
}

::selection {
  color: #000;
  background: #dbdbdb;
}

body {
  font-family: "IBM Plex Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
}

.page-heading {
  font-size: 20px;
  font-weight: 600 !important;
  margin-bottom: 0;
  font-family: Nunito, sans-serif;
  line-height: 18px;
  color: #364a63;
}

.flex {
  display: flex;
}
.column{
 flex-direction: column;
}
.wrap{
  flex-wrap: wrap;
}

.aic {
  align-items: center;
}

.jcsb {
  justify-content: space-between;
}

.gap10 {
  gap: 10px;
}

.jce {
  justify-content: end;
}

.jcc {
  justify-content: center;
}
.jcfs {
  justify-content: flex-start;
}
.aifs {
  align-items: flex-start;
}
.ml32{
  margin-left: 32px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.7rem;
  margin-top: 20px;
}

.card {
  background-color: #fff;
  padding-block: 10px;
  padding-inline: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 250px;
}

.card-heading {
  color: #526484;
  font-size: 15px;
  font-weight: 600;
}

.card-label {
  color: #526484;
  font-size: 13px;
  font-weight: 500;
}

.card-label-width {
  flex: 1;
}

.card-title {
  color: #526484;
  font-size: 13px;
  font-weight: 500;
  flex: 1;
}

.rowtxt {
  display: flex;
  /* align-items: center; */
  gap: 8px;
  margin-top: 7px;
  /* max-width: 400px; */
}

.view-btn {
  color: #1b64af;
  font-size: 18px;
  font-weight: 600;
  text-align: right;
  cursor: pointer;
}

:hover.view-btn {
  transition: linear;
}

.container {
  padding: 20px;
  overflow: hidden;
}

.mediumtxt {
  font-family: "IBM Plex Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  color: #6f6f6f;
  font-weight: 500;
  /* line-height: 24px; */
}

.txt {
  font-family: "IBM Plex Sans", sans-serif;
  -webkit-font-smoothing: antialiased;

  /* line-height: 24px; */
}

.df {
  display: flex;
}

.tooltip {
  position: relative;
  display: inline-block;
  /*border-bottom: 1px dotted #ccc;
    color: #006080; */
}

.tooltip .tooltiptext.light {
  background-color: #ffffff;
  color: #333333;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.tooltip .tooltiptext.dark {
  background-color: #555555;
  color: #ffffff;
}

.tooltip .tooltiptext {
  visibility: hidden;
  position: absolute;
  width: 190px;

  text-align: center;
  padding: 5px;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip-right {
  top: -5px;
  left: 125%;
}

.tooltip-right::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #555 transparent transparent;
}

.tooltip-bottom {
  top: 135%;
  left: 50%;
  margin-left: -60px;
}

.tooltip-bottom::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 11px;
  border-style: solid;
  border-color: transparent transparent #55555530 transparent;
}

.tooltip-top {
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
}

.tooltip-top::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip-left {
  top: -5px;
  bottom: auto;
  right: 128%;
}

.tooltip-left::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #555;
}

.arrow {
  position: absolute;
  left: 48%;
  top: -10px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid #fff;
  z-index: 1;
}

/* Option with Input box CSS */

.optionContainer {
  position: absolute;
  z-index: 1;
  top: 65px;
  width: 100%;
  max-height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
  padding: 0.25rem;
  border-radius: 0.15rem;
}

.optionContainer ::-webkit-scrollbar {
  display: none;
}

.optionItem {
  width: 100%;
  padding: 0.25rem 0.25rem;
  cursor: pointer;
  font-size: 12px;
}

.optionItem:hover {
  background-color: rgb(218, 213, 213);
}

/* ExpenditureOtherPurchase component CSS  by Naveen */

.EOP-main {
  min-height: 50%;
  width: 100%;
  margin: 5rem 0 0 5rem;
}
.EOP {
  width: 60%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1rem;
  background-color: #e5e9f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #145272;
  font-weight: bold;
}
.EOP-1st {
  width: 100%;
  height: 100%;
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.EOP-1st > div {
  height: 100%;
  width: 25%;
  padding: 0 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.itc-div {
  width: 100%;
}
.nd-item {
  flex-wrap: wrap;
}
.staff-heading {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 0.5rem;
  font-size: 30px;
  gap: 1rem;
  margin-bottom: 1rem;
}
.credit-limit {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.credit-limit > div {
  width: 19%;
  padding: 0.15rem 0.5rem;
}

.l24 > div {
  width: 24% !important;
  padding: 0.15rem 0.5rem;
}
.stock-cards {
  display: flex;
  width: 100%;
  gap: 1rem;
}
.stock-card {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.158);
}
.profile-card-2 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

/* CSS for Invoice formats */

.inv-td {
  background-color: white;
  color: black;
}

/* CSS for Invoice formats end */

/* Alerts message div */

.mess_alert {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.cursor-pointer {
  cursor: pointer;
}
.profile-card {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: space-between;
  gap: 1rem;
  align-items: flex-start;
}
.bank_master_hover:hover{
text-decoration: underline;
color: #1b64af;
}

@media screen and (max-width: 1000px) {
  .credit-limit > div {
    width: 23%;
    padding: 0.15rem 0.5rem;
  }
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
.emp_list_itom{
  width: 200px;
  justify-content: space-between;
}

@media screen and (max-width: 800px) {
  .credit-limit > div {
    width: 30%;
    padding: 0.15rem 0.5rem;
  }
}
@media screen and (max-width: 500px) {
  .emp_list_itom{
    width: 100%;
    justify-content: space-between;
  }
  .emp_sub_list_itoms{
    flex-direction: column !important;
  }
  .container {
    padding: 5px;
  }
  .serv-item {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.25rem 0 !important;
  }
  .page-heading {
    font-size: 16px;
  }
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .inp-label {
    font-size: 12px !important;
  }
  .rdio-item {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .pin-input-div {
    width: 50% !important;
  }
  .profile-card {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .profile-card-1 {
    padding: 0;
    display: flex;
    width: 100%;
    justify-content: flex-start;
  }
  .profile-card-2 {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  .credit-limit {
    flex-wrap: wrap;
  }
  .credit-limit > div {
    width: 100% !important;
  }
  .staff-heading {
    font-size: 20px;
  }

  .stock-cards {
    flex-direction: column;
    width: 100% !important;
  }
  .stock-card {
    width: 100%;
  }
}

/* Abhishek Css */

.credit-limit1 > div {
  min-width: 31% !important;
}

/* Tax Invoice CSS */

.border_left {
  border: none !important;
  border-left: 1px solid #000 !important;
  font-size: 10px !important;
  color: #000;
  padding: 3px !important;
}
.border_right {
  border: none !important;
  color: #000;
  padding: 3px !important;
  border-right: 1px solid #000 !important;
  font-size: 10px !important;
}
.both {
  border: none !important;
  border-top: 1px solid #fff !important;
  border-right: 1px solid #000 !important;
  border-left: 1px solid #000 !important;
}
.border_top {
  border-top: 1px solid #000 !important;
}

.borde_top_white {
  border-top: 1px solid #fff !important;
}
.td_1 {
  max-width: 350px;
  color: #000 !important;
  font-size: 10px !important;
  border: 1px solid black;
  padding: 2px;
}

.boldBorder {
  border-top: 1px solid #000 !important;
  font-weight: bold;
}

.link_days {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.toggle_div {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  cursor: pointer;
}

/* GSTR CSS */
